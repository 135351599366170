import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Accordion from '../../../../components/Accordion.js'
import Layout from '../../../../components/layout'

const GrihaAll = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Pandit for Griha Pravesh Puja in Bangalore (15+yrs Exp): Book Pandits for Gruhapravesam Bangalore & Housewarming Ceremony, Pandit for Housewarming puja in Bangalore</title>
            <meta name="description" content="Need Pandit for Griha Pravesh puja in Bangalore? We provide the Best Pandits for Gruhapravesam in Bangalore, India for top milk boiling or Shubh Griha Pravesh Kalash Pooja for your simple new home opening or apartment entry in Bangalore, India and housewarming ceremony in your own language, tradition & culture, pandit for housewarming puja in bangalore." />
            <script type="application/ld+json">{`
                 {
                  "@context":"https://schema.org",
                  "@type":"FAQPage",
                  "mainEntity": [
                    {
                      "@type":"Question",
                      "name":"Should the customer arrange for Puja Samagri or would the Panditji arrange?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja"
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Will the Panditji help the customer in choosing good muhurth for ritual?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How much time would it take for the Puja to be completed?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Griha Pravesh Puja would take about 4-6 hours to get completed. We can arrange shorter or longer pujas as per the customer's requirement."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How can the customers pay? Through cash or Wallet or Netbanking?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)"
                      }
                    }
                  ]
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                  "author": "NamasteGod",
                  "name": "${data.allMarkdownRemark.edges[2].node.frontmatter.title}",
                  "contentUrl": "https://www.namastegod.com${data.allFile.nodes[2].childImageSharp.fluid.src}",
                  "contentLocation": "Bangalore, India"
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Product",
                  "name": "Pandits for Griha Pravesh in Bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[2].childImageSharp.fluid.src}",
                  "description": "Need Pandit for Griha Pravesh puja in Bangalore? We provide the Best Pandits for Gruhapravesam in Bangalore, India for top Milk Boiling or Shubh Griha Pravesh Kalash Pooja for your simple New Home opening or Apartment Entry in Bangalore, India and Housewarming Ceremony in your own language, Tradition & Culture.",
                  "sku": "0000000006",
                  "mpn": "000006",
                  "brand": {
                    "@type": "Brand",
                    "name": "NamasteGod"
                  },
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5",
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rekha Anoop"
                    }
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "101"
                  },
                  "offers": {
                    "@type": "Offer",
                    "url": "https://www.namastegod.com/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-house-warming-ceremony-gruhapravesam-in-bangalore",
                    "priceCurrency": "INR",
                    "price": "3100",
                    "priceValidUntil": "2020-12-31",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                      "@type": "Organization",
                      "name": "NamasteGod"
                    }
                  }
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "LocalBusiness",
                  "name": "Pandits for Griha Pravesh in Bangalore",
                  "telephone": "(+91) 81972 49957",
                  "priceRange": "₹₹₹",
                  "url": "https://www.namastegod.com/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/pandit-for-house-warming-ceremony-gruhapravesam-in-bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[2].childImageSharp.fluid.src}",
                  "address":
                  {
                    "@type": "PostalAddress",
                    "addressLocality": "Bangalore",
                    "addressRegion": "KA",
                    "addressCountry": "IN",
                    "postalCode": "560078",
                    "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                   }
                }
            `}</script>
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Pandit for Griha Pravesh puja in Bangalore<br/>(15+yrs Exp): Book Pandits for Gruhapravesam<br/> Bangalore & Housewarming Ceremony</h1>
                        <h1 className="mobile">Pandit for Griha Pravesh puja<br/>in Bangalore(15+yrs Exp):Book Pandits<br/>for Gruhapravesam Bangalore &<br/>Housewarming Ceremony</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Need Pandit for Griha Pravesh puja in Bangalore? We provide the Best <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Pandits</a> for Gruhapravesam in Bangalore, India for top Milk Boiling or Shubh Griha Pravesh Kalash Pooja for your simple New Home opening or Apartment Entry in Bangalore, India and Housewarming Ceremony in your own language, Tradition & Culture.</p>
                    <Link className="button special" to="#contact">Contact Us</Link>
                    <Accordion
          id="1"
          question=" more"
          answer="<p>Griha Pravesh puja starts by offering prayers to Lord Ganesha by Pandits, Which is followed by the puja of the presiding God of the owner, Dwara puja and pravesham that is entering house. Griha Pravesh Puja is one of the most important ritual that is performed all over Bangalore with lot of happiness and devotion. An important ritual that is performed by pandit during Griha Pravesh puja is Vaastu Puja and Satyanarayan Puja. Finally the puja ends with fire ritual, milk boiling and then offering the food to the pandits or brahmins. Namaste God offers qualified pandits who have more than 15+ years of experience in performing Griha Pravesh puja ceremony. Choose pandit ji based on your traditions, culture or region and book Griha Pravesh Puja with us on namastegod.com</p>
<h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>How much time would it take for the Puja to be completed?
<span>Griha Pravesh Puja would take about 4-6 hours to get completed. We can arrange shorter or longer pujas as per the customer's requirement.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions_all}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order})  {
    edges {
      node {
        frontmatter {
          order
          title
          experience
          degree
          regions_all
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Ashutosh Pandey", "Pandit Bharat Kumar", "Pandit Hem Chandra Joshi", "Pandit Jayarama Sharma", "Pandit Subodh Pandey", "Pandit TManjunath"]}}, sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default GrihaAll